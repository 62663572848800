import { memo as Memo, useRef, useCallback, useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation';
import { TimelineLite, TweenMax } from 'gsap';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Components
import Text from '../Text';
import CustomLink from '../CustomLink';
import { SocIconStroke1, SocIconStroke2, SocIconStroke3, SocIconStroke4 } from '../SvgComponents/SocIconStroke';

//* Styles
import SocIconStyle from './style';

const SocIcon = Memo(({ type, menuIcon, whiteIcon, isSocial, isFooter, className, globalData }) => {
	//! Pathname
	const pathname = usePathname();

	//! State
	const [isCopied, setIsCopied] = useState(false);

	//! States
	const cardRef = useRef();

	//! Data
	const socialObj = useMemo(() => globalData?.socials?.reduce((o, i) => ({ ...o, [i.name]: i }), {}), [globalData]);

	const url = useMemo(() => process.env.NEXT_PUBLIC_URL + pathname, [pathname]);
	const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
	const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
	const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

	//! Animation
	const tl = useMemo(() => new TimelineLite({ paused: true }), []);

	//! Mouse Over
	const mouseOver = useCallback(
		(e) => {
			if (cardRef.current) {
				tl.to(cardRef.current.childNodes[e], 0.2, { rotation: e % 2 == 0 ? 15 : -15 }).play();
			}
		},
		[cardRef]
	);

	//! Mouse Over
	const mouseleave = useCallback(
		(e) => {
			tl.to(cardRef.current.childNodes[e], 0.2, { rotation: 0 }).play();
		},
		[cardRef]
	);

	const Animation = useCallback(() => {
		const el = Math.floor(Math.random() * Math.floor(isFooter ? 7 : 3));

		if (cardRef.current && type !== 1 && menuIcon === true) {
			TweenMax.set(cardRef.current.childNodes[el]?.childNodes?.[0], { height: 0, duration: 0.6, delay: 7 });
			TweenMax.to(cardRef.current.childNodes[el]?.childNodes?.[0], 0.7, {
				height: '100%',
				delay: 7.1,
				onComplete: () => {
					Animation();
				},
			});
		}
	}, [type, menuIcon, cardRef, isFooter]);

	//! Copy url function
	const copyUrl = () => {
		const el = document.createElement('input');
		el.value = window.location.href;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	};

	const handleCopyLinkClick = () => {
		copyUrl();
		setIsCopied(true);

		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	};

	useEffect(() => Animation(), []);

	return (
		<SocIconStyle
			ref={cardRef}
			className={`${className || ''} ${whiteIcon ? 'whiteIcon' : ''} ${type === 1 ? 'soc_icon_wrap1' : 'soc_icon_wrap2'}`}>
			<CustomLink
				external
				ariaLabel='Concept Studio Facebook'
				url={isSocial ? socialObj?.facebook.url : facebookUrl}
				className={`soc-icon-item crCircle soc_icon_item`}
				onMouseEnter={() => mouseOver(0)}
				onMouseLeave={() => mouseleave(0)}>
				<div className={`social-icon-border soc_icon_border`}>
					<Text
						tag={'span'}
						className={`soc_icon ${type === 1 ? 'cs-facebook' : ' cs-facebook-circle'}`}
					/>
				</div>
				{type === 1 ? <SocIconStroke1 /> : ''}
			</CustomLink>

			<CustomLink
				external
				ariaLabel='Concept Studio Twitter'
				onMouseEnter={() => mouseOver(1)}
				onMouseLeave={() => mouseleave(1)}
				className={`soc-icon-item crCircle soc_icon_item`}
				url={isSocial ? socialObj?.twitter.url : twitterUrl}>
				<div className={`social-icon-border soc_icon_border`}>
					<Text
						tag={'span'}
						className={`soc_icon cs-twitter`}
					/>
				</div>
				{type === 1 ? <SocIconStroke2 /> : ''}
			</CustomLink>

			<CustomLink
				external
				url={isSocial ? socialObj?.linkedin.url : linkedinUrl}
				ariaLabel='Concept Studio Linkedin'
				className={`soc-icon-item crCircle soc_icon_item`}
				onMouseEnter={() => mouseOver(2)}
				onMouseLeave={() => mouseleave(2)}>
				<div className={`social-icon-border soc_icon_border`}>
					<Text
						tag={'span'}
						className={`soc_icon cs-linkedin`}
					/>
				</div>
				{type === 1 ? <SocIconStroke3 /> : ''}
			</CustomLink>

			{isFooter && (
				<>
					{socialObj?.instagram?.url ? (
						<CustomLink
							external
							url={socialObj.instagram.url}
							ariaLabel='Concept Studio instagram'
							className={`soc-icon-item crCircle soc_icon_item`}
							onMouseEnter={() => mouseOver(3)}
							onMouseLeave={() => mouseleave(3)}>
							<div className={`social-icon-border soc_icon_border`}>
								<Text
									tag={'span'}
									className={`soc_icon cs-instagram`}
								/>
							</div>
						</CustomLink>
					) : (
						''
					)}

					{socialObj?.behance?.url ? (
						<CustomLink
							external
							url={socialObj.behance.url}
							ariaLabel='Concept Studio behance'
							className={`soc-icon-item crCircle soc_icon_item`}
							onMouseEnter={() => mouseOver(4)}
							onMouseLeave={() => mouseleave(4)}>
							<div className={`social-icon-border soc_icon_border`}>
								<Text
									tag={'span'}
									className={`soc_icon cs-behance`}
								/>
							</div>
						</CustomLink>
					) : (
						''
					)}

					{socialObj?.clutch?.url ? (
						<CustomLink
							external
							url={socialObj.clutch.url}
							ariaLabel='Concept Studio clutch'
							className={`soc-icon-item crCircle soc_icon_item`}
							onMouseEnter={() => mouseOver(5)}
							onMouseLeave={() => mouseleave(5)}>
							<div className={`social-icon-border soc_icon_border`}>
								<Text
									tag={'span'}
									className={`soc_icon cs-clutch`}
								/>
							</div>
						</CustomLink>
					) : (
						''
					)}

					{socialObj?.vimeo?.url ? (
						<CustomLink
							external
							url={socialObj.vimeo.url}
							ariaLabel='Concept Studio vimeo'
							className={`soc-icon-item crCircle soc_icon_item`}
							onMouseEnter={() => mouseOver(6)}
							onMouseLeave={() => mouseleave(6)}>
							<div className={`social-icon-border soc_icon_border`}>
								<Text
									tag={'span'}
									className={`soc_icon cs-vimeo`}
								/>
							</div>
						</CustomLink>
					) : (
						''
					)}
				</>
			)}

			{type === 1 ? (
				<div
					onMouseEnter={() => mouseOver(3)}
					onMouseLeave={() => mouseleave(3)}
					onClick={() => handleCopyLinkClick()}
					className={`soc-icon-item crCircle soc_icon_item copy-icon-block`}>
					<div className={`social-icon-border soc_icon_border`}>
						<Text
							tag={'span'}
							className={`soc_icon cs-copy-link`}
						/>
					</div>

					<SocIconStroke4 />

					{isCopied ? (
						<Text
							className={'copied-text'}
							text={'copied'}
						/>
					) : null}
				</div>
			) : (
				''
			)}
		</SocIconStyle>
	);
});

export default withUIContext(SocIcon, ['globalData']);
